import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlTravelLists, dbUrlWTDlist } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { SearchLists, Mobile } from "../misc/utils";
//import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';

const TravelLists = () => {
    const [destinations, setDestinations] = useState([])
    const [list, setList] = useState([])
    const [searchString, setSearchString] = useState('')
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [modalImage, setModalImage] = useState(null);

    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchDestinations = () => {
          axios.get(dbUrlTravelLists + params.slug)
          .then(response => {
            setLoading2(false)
            setDestinations(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        fetchList()
        fetchDestinations()
      }, [params.slug])
  
    if (error) return `Error tv: ${error.message}`;
    if (errorList) return `Error list: ${errorList.message}`;

    const isMobile = Mobile();

    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    let filterArray

    //Search
    if (searchString) {
        filterArray = SearchLists(destinations, 'searchdestination', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    if (!searchString) {
        filterArray = destinations;
    }

    const {name, description, pic_link, page, rick_steves} = list[0];

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                <h1>{value.name}</h1>
            
                <div className="row mb-3 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-3 Description">

                        <div className="mt-2"><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">total count: {destinations.length}</div>
                                </div> 
                            </div>
                            }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-6 Description">
                        
                        {value.pic2_link &&
                        <div className="mt-2 text-center">
                            <img src={value.pic2_link} alt="alt" width="400" className="img-fluid" /> 
                        </div>
                        }
                        {!isMobile && rick_steves &&
                        <div className="my-1" align="Right">
                            <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" 
                            data-bs-target="#mapModalHeading">
                                <b>Show large map</b>
                            </button>
                        </div>
                        }
                        <div className="modal fade" id="mapModalHeading" aria-labelledby="mapModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">

                                    <div className="modal-header">          
                                        <h5 className="modal-title" id="mapModalLabel">Map</h5>
                                    </div>

                                    <div className="modal-body">
                                    
                                        <img src={value.pic2_link}
                                            className="img-fluid rounded" alt=""></img>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {value.description &&
                            <div className="factText14 rounded mt-3 p-2">{value.description}</div>
                        }
                        
                        {value.comment1 && 
                            <div className="card bg-light mt-2 py-2 px-2">
                                <div className="Quote mb-3">{value.comment1}</div> 
                                <div className="blockquote-footer"><i>{value.source1} ({value.details1})</i></div>
                            </div>  
                        }
                        
                       
                    </div>

                    {/* kolonne3 */}
                    <div className="col-sm-3">
                        
                        <div>
                            <div className="row mt-3">   
                                    <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>
                            <div className="row">  
                                <div className="col-9">
                                    <div className="search">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={1000}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all destinations" 
                                        />
                                    </div>
                                </div>
                                <div className="col-3 pl-0">
                                    <button type="button" className="btn btn-primary"  
                                    onClick={() => clearSearchField()}>Reset</button>
                                </div>
                            </div>
                        </div>

                        {value.google_maps &&
                            <div className="mt-2">
                                <div className="rounded p-2">
                                <b>My map @ <a href={value.google_maps} target="_blank" rel="noreferrer">Google maps</a></b>
                                </div>
                            </div>
                        }

                        {value.source_url &&
                            <div className="mt-4 px-2">
                                <b>List source</b>
                                <div className="Description">
                                    <ul>
                                        <li>
                                            <b><a href={value.source_url}>
                                                {value.source}
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        }

                        <div className="mt-4"><b>Check out</b></div>
                            <div className="Description">
                                <ul>
                                    {
                                        (() => {
                                        if (value.link1 && value.link2 && value.link3 && value.link4) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url3}>
                                                        {value.link3}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url4}>
                                                        {value.link4}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                        if (value.link1 && value.link2 && value.link3) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url3}>
                                                        {value.link3}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                        if (value.link1 && value.link2) {
                                        return  <div><li><b><a href={value.url1}>
                                                    {value.link1}
                                                </a></b>
                                                </li>
                                                <li><b><a href={value.url2}>
                                                    {value.link2}
                                                </a></b>
                                                </li>
                                                </div>
                                        }
                                        return <div><li><b><a href={value.url1}>
                                                    {value.link1}
                                                </a></b>
                                                </li>
                                                </div>
                                        })()
                                    }
                                    
                                </ul>
                            </div>   
                            
                            <div className="mb-1 mt-4 Text28">
                            <button type="button" className="btn btn-sm btn-danger"><b>Must See</b></button> - &#9650;&#9650;&#9650;
                            </div>
                            <div className="mb-1 Text28">
                            <button type="button" className="btn btn-sm btn-success"><b>Try Hard to See</b></button> - &#9650;&#9650;
                            </div>
                            <div className="mb-1 Text28">
                            <button type="button" className="btn btn-sm btn-primary"><b>Worthwhile</b></button> - &#9650;
                            </div>

                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const DestinationObjects = filterArray.map(value => {
        
        let DescCharLimit

        if (value.desc_char_limit) {
            DescCharLimit = value.desc_char_limit;
        }
        else {
            DescCharLimit = 1000
        }

        



        return (

            <React.Fragment key={value.id}>

                

                <div className="row mb-4 border rounded border-2">
                    {/* 1 kolonne */}
                    <div className="col-sm-3 py-2">
                        <img src={value.picture||value.dest_picture} className="img-fluid rounded" alt="">
                        </img>
                        <div className="text-center">{value.picture_name}</div>
                        <div className="mt-2">
                            {value.dest_region &&
                                <div>
                                    <b>Region</b> <br />
                                    <div className="DateTxt rounded p-2">{value.dest_region}</div>
                                </div>
                            }
                            {value.region && !value.dest_region &&
                                <div>
                                    <b>Region</b> <br />
                                    <div className="DateTxt rounded p-2">{value.region}</div>
                                </div>
                            }
                            {value.france_department &&
                                <div>
                                    <b>Department</b> <br />
                                    <div className="DateTxt rounded p-2">{value.france_department}</div>
                                </div>
                            }
                            {value.prefecture &&
                                <div>
                                    <b>Prefecture</b> <br />
                                    <div className="DateTxt rounded p-2">{value.prefecture}</div>
                                </div>
                            }
                            {value.england_county &&
                                <div>
                                    <b>County</b> <br />
                                    <div className="DateTxt rounded p-2">{value.england_county}</div>
                                </div>
                            }
                            {value.population &&
                                <div>
                                    <b>Population</b> <br />
                                    <div className="DateTxt rounded p-2">{value.population}</div>
                                </div>
                            }
                        </div>
                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-6 pb-3 border-right">
                        
                        <div className="pt-2">
                            {(value.importance === 1) &&
                                <h3><span className="badge bg-danger">{value.city||value.dest_name}</span></h3>
                            }
                            {(value.importance === 2) && 
                                <h3><span className="badge bg-success">{value.city||value.dest_name}</span></h3>
                            }
                            {(value.importance === 3) &&
                                <h3><span className="badge bg-primary">{value.city||value.dest_name}</span></h3>
                            }
                            {!value.importance &&
                                <h3>{value.city||value.dest_name}</h3>
                            }
                            
                            
                        </div>

                        <div className="factText rounded my-2 p-2"> 
                                    <ReadMoreAndLess
                                        charLimit={DescCharLimit}
                                        readMoreText={" Read more ▼"}
                                        readLessText={" Read less ▲"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                    {value.description||value.dest_description}
                                </ReadMoreAndLess> 
                                    {value.wiki &&
                                    <div className="fst-italic text-end p-1">
                                     - <a href={value.wikipedia_link||value.wiki_link}>wikipedia..</a>
                                    </div>
                                    }
                        </div>
                        
                        {value.rs_description &&
                        <div className="mt-3">
                            <b>Rick Steves:</b> <br />
                            <div className="rickStevesText rounded my-2 p-2"> 
                                        <ReadMoreAndLess
                                            charLimit={DescCharLimit}
                                            readMoreText={" Read more ▼"}
                                            readLessText={" Read less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                            >
                                            {value.rs_description}
                                        </ReadMoreAndLess> 
                            </div>
                        </div>
                        }
                        
                        

                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-3 pt-2">

                        {value.main_attrations && !rick_steves &&
                            <div>
                                <div className="PeopleHeader py-1 ps-2 rounded"><b>Main Attractions</b></div>
                                <div className="pt-2 border-top Text13">
                                    <ReactMarkdown>
                                        {value.main_attrations}               
                                    </ReactMarkdown>
                                </div>
                            </div>
                        }
                        {rick_steves && value.at_a_glance &&
                            <div>
                                <div className="PeopleHeader py-1 ps-2 rounded"><b>At a glance</b></div>
                                <div className="pt-2 border-top Text13">
                                    <ReactMarkdown>
                                        {value.at_a_glance}               
                                    </ReactMarkdown>
                                </div>
                            </div>
                        }
                        {value.dest_main && !rick_steves &&
                            <div>
                                <div className="PeopleHeader py-1 ps-2 rounded"><b>Main places</b></div>
                                <div className="pt-2 border-top Text13">
                                    <ReactMarkdown>
                                        {value.dest_main}               
                                    </ReactMarkdown>
                                </div>
                            </div>
                        }
                        {rick_steves && value.at_a_glance_location &&
                            <div>
                                <div className="PeopleHeader py-1 ps-2 rounded"><b>At a glance</b></div>
                                <div className="pt-2 border-top Text13">
                                    <ReactMarkdown>
                                        {value.at_a_glance_location}               
                                    </ReactMarkdown>
                                </div>
                            </div>
                        }

                        <img src={value.map} className="img-fluid rounded" alt="">
                        </img>

                        {!isMobile && value.map &&
                        <div className="my-1" align="Right">
                            <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" 
                            data-bs-target="#mapModal" onClick={() => {setModalImage(value.map);}}>
                                <b>Show large map</b>
                            </button>
                        </div>
                        }

                        <div className="modal fade" id="mapModal" aria-labelledby="mapModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">

                                    <div className="modal-header">          
                                        <h5 className="modal-title" id="mapModalLabel">Map</h5>
                                    </div>

                                    <div className="modal-body">
                                    
                                        <img src={modalImage}
                                            className="img-fluid rounded" alt=""></img>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </React.Fragment>

        )

    })



      return (
        <main className="my-1 py-1">
            {destinations.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                             {DestinationObjects} 
                        </div>
                    </div>

                    <div align="Right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </div>


                </div>
            )}
        </main>
    )
}

export default TravelLists